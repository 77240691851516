




































import { getExactAlternativeEnterpriseLibraryById, getIndustryTypeList, saveIntermediariesProject, savePreRegistrationProject } from '@/common/api/modules/exactAlternativeEnterpriseLibrary';
import router from '@/router';
import { defineComponent, ref,reactive, onMounted, computed } from '@vue/composition-api'

import { ElForm } from 'element-ui/types/form';
import { Message } from 'element-ui';
import Vue from 'vue';
type FormItem = {
  label:string,
  required?:boolean,
  key:keyof PreRegistrationProject,
  type:string,
  options?:any[],
  rule:any[]
}
export default defineComponent({
   setup(prop,ctx) {
     console.log(`setup运行`,{prop,ctx})
    //  const route = Vue.prototype.$route
    //产业类型字典
    // const industryTypeCodeDict = new Dictionary(DictionaryName.INDUSTRY_CATEGORY)
   

     
    /** 表单结构 */
    const formStructure = reactive<FormItem[]>([
      {label:"产业类别",required: true,key:"industryTypeCode",type:"select" ,options:[],rule:[] },	
      {label:"企业名称",required: true,key:"enterpriseName",type:"text",rule:[] },	
      {label:"项目名称",required: true,key:"projectName",type:"text",rule:[] },	
      {label:"统一社会信用代码",required: true,key:"socialCode",type:"text",rule:[] },	
      {label:"项目介绍",required: true,key:"projectIntroduction",type:"textarea",rule:[] },	
      {label:"公司介绍",required: true,key:"enterpriseIntroduction",type:"textarea",rule:[] },	
      {label:"产值",required: true,key:"outputValue",type:"textarea",rule:[] },	
      {label:"原工厂面积",required: true,key:"originalFactoryArea",type:"textarea",rule:[] },	
      {label:"项目内容",required: true,key:"projectContent",type:"textarea",rule:[] },	
      {label:"项目效益",required: true,key:"projectBenefits",type:"textarea",rule:[] },	
      {label:"企业述求",required: true,key:"enterpriseStatement",type:"textarea",rule:[] },	
      {label:"目前进展情况",required: true,key:"currentProgress",type:"textarea",rule:[] },	
      {label:"引荐人",required: true,key:"referrer",type:"text",rule:[] },	
      {label:"联系人",required: true,key:"linkMan",type:"text",rule:[] },	
      {label:"联系人电话",required: true,key:"linkManPhone",type:"text",rule:[] },	
      {label:"下一步工作计划",required: true,key:"nextWorkPlan",type:"textarea",rule:[] },	
      {label:"备注",required: false,key:"remark",type:"textarea",rule:[] },
    ])
   
      //表单字段
      let formData = ref<PreRegistrationProject>({
        industryTypeCode:'' ,	
        enterpriseName:'' ,	
        projectName:'' ,	
        socialCode:'' ,	
        projectIntroduction:'' ,	
        enterpriseIntroduction:'' ,	
        outputValue:'' ,	
        originalFactoryArea:'' ,	
        projectContent:'' ,	
        projectBenefits:'' ,	
        enterpriseStatement:'' ,	
        referrer:'' ,	
        linkMan:'' ,	
        linkManPhone:'' ,	
        currentProgress:'' ,	
        nextWorkPlan:'' ,	
        remark:'' ,
      });
      const saveFormRef = ref<ElForm>()
      const handleSubmit = async ()=>{
       const validate =  await saveFormRef.value?.validate()
       if(!validate){
         Message.error("请检查表单");
         return ;
       }
       let res = await saveIntermediariesProject(formData.value);
        console.log("表单提交",formData,{saveFormRef,res,validate});
        Message.success("保存成功")
        router.go(-1)
      }
      const handleCancel = ()=>{
        router.go(-1)
      }

      const  id = computed(()=>{
        console.log("获取id",ctx)
        return ctx.root.$route.params.id;
      })
     async function initIndustryTypeList(){
        const industryTypeList =  await  getIndustryTypeList();
        formStructure[0].options = industryTypeList.map(({itemDesc:label,itemValue:value})=>({label,value}))
     }
     async function initProjectDetaile(id:string){
        let data = await getExactAlternativeEnterpriseLibraryById(id)
        console.log("初始化详情",data)
        formData.value = data;
     }

      onMounted(()=>{
          initIndustryTypeList();
          if(id.value){
             console.log("Mounted",id.value)
             initProjectDetaile(id.value)
          }

      })
      return {formData,formStructure,saveFormRef,id,handleSubmit,handleCancel}
  },
})
